.globalNav {
  width: 100%;
  max-width: 1280px;
  background: transparent;
  position: fixed;
  bottom: 0;
  z-index: 100;
  /* opacity: 0; */
  /* left: auto; 
    right:auto; */
  background: lightgray;

  /* margin-left: -400px; Half of the width */
  /* height:100px; */
  /* border:thin solid black; */
}
.companyContainer {
  min-height: 800px;
  z-index: 0;
  width: 100%;
  max-width: 1280px;
  overflow-y: scroll;
  position:relative;
  overflow-x: hidden;
}
.companyText {
  background-color: rgb(25, 25, 71);
  color: white;
  /* height:1000px; */
  text-align: center;
  padding: 40px 0px;
  font-size: 24px;
}
.companyText h1 {
  color: #39c;
  font-size: 42px;
}
.companyText h2 {
  color: #39c;
}
.companyText .logo {
  background-color: rgb(25, 25, 71);
  width: 200px;
  height: 200px;
  /* margin-left: auto;
  margin-right: auto; */
  border: 4px solid white;
}
.smallSection img {
  width: 100%;
  padding: 10px;
}
.smallSection p {
  font-size: 16px;
  padding: 10px;
  padding-top: 20px;
}
.smallSection li {
  font-size: 16px;
  text-align: left;
}
.smallSection h2 {
  padding: 10px;
}
.logo img {
  width: 100%;
  height: 100%;
}
.companyTitle {
  text-align: center;
  width: 100%;
  font-size: 40px;
}
.subTitle {
  text-align: center;
  width: 100%;
  font-size: 35px;
}
.about img {
  height: 260px;
  width: auto;
  max-width: 100%;
  filter: grayscale(100%);
}
.bottomRiser {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: black;
}
.buttonWrapper div {
  background-color: grey;
  color: white;
  width: 140px;
  text-align: center;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 20px;
  padding: 10px 5px;
  cursor: pointer;
}
.sliderImage {
  width: 400px;
  height: 400px;
}
.sliderImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.sectionTwo_image {
  width: 300px;
  height: 200px;
}
.sectionTwo_image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.sectionThree {
  margin: 40px 0;
  padding: 0 20px;
  min-height: 640px;
  background:white;
  padding-bottom: 20px;
}
.sectionThree h1 {
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: 600;
}
.sectionThree_grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.buttonWrapper div:hover {
  opacity: 0.9;
}

.bottomAnimationHeading {
  color: 'white';
  font-size: 60;
  margin-top: 20;
}
.bgClassName {
  width: 100%;
  height: 900px;
}
@media only screen and (min-width: 1280px) {
  .globalNav {
    left: 50%;
    margin-left: -640px;
  }
  .companyContainer {
    left: 50%;
    margin-left: -640px;
  }
}

@media only screen and (max-width: 1280px) {
  .bottomAnimationHeading {
    color: 'white';
    font-size: 40;
    margin-top: 20;
  }
}

.bottomAnimation_title {
  color: white;
  font-size: 60px;
  margin: 0;
}
.bottomAnimation_company {
  color: white;
  line-height: 30px;
  font-size: 60px;
  margin: 0;
}
@media only screen and (max-width:600px) {
  .bottomAnimation_title {
    font-size: 28px;
  }
  .bottomAnimation_company {
    font-size: 28px;
  }
  .buttonWrapper div{
    font-size: 16px;
    line-height: 16px;
  }
  .px-1 {
    font-size: 14px;
  }
  .sectionTwo_image {
    width: 100%;
  }
  .logo img {
    width: 142px;
    height: 142px;
  }
  .companyText .logo {
    background-color: rgb(25, 25, 71);
    width: 150px;
    height: 150px;
    /* margin-left: auto;
    margin-right: auto; */
    /* border: 4px solid white; */
  }
}