

:root {
  --hovercard-height:360;
  background-color:#e7e6e3;
}
#map, .map {
  height: 100%;
}

.loader {
  background-color: #107FC9;
}

.App {
  /* text-align: center; */
  /* background-color:#FAF9F6; */
  background-color:#e7e6e3;
  /* min-height:100vh;
  height:800px;
  max-height:1200px; */
  /* swidth:100%; */
  margin:0;
  height:100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media screen and (min-width: 1280px) {
  .mainContainer
  {
    position: relative;
    height:640px;
    max-height:640px;
    max-width:1280px;
  }
  .fade-in-hello
{
  animation: fade .75s ease-in; 
  background:linear-gradient(25deg, #191C2F, #107FC9);
  opacity:.97;
  min-height: 600px;
  height:90vh;
  width:320px; 
  max-width: 320px;
  position:'absolute';
  top:120px;
  left:0;
  margin-left:320px;
  /* margin-left:320px; */
  z-index:10000;
}
}
@media screen and (max-width: 1280px) {
  .mainContainer
  {
    width:100%;
    max-width: 100%;
    position: relative;
  }
  .fade-in-hello
{
  left:25%;
  width:25%;
}
}
.App-link {
  color: #61dafb;
}
.slides{  
  min-height: 640px;
  /* height:90vh; */
  height:640px;
  padding:0px;
  margin-left:-10px;
  margin-right:-10px;
}
.slides .imgWrapper, .imgWrapper img
{
  z-index:1;
  min-height: 640px;
  height:640px;
  /* height:90vh; */
  width:100%;
  position:relative;
}


.slides .hoverCardTop{
  z-index:111;
  opacity:.9;
  min-height:var(--hovercard-height);
  height:var(--hovercard-height);
  position: absolute;
  top:0;
  height:320px;
  min-height:320px;
  margin:0;
   -webkit-transform: translateY(00%);
			transform: translateY(00%);
	transition: transform 200ms linear;
   width: -webkit-calc(100% + 20px);
    width:    -moz-calc(100% + 20px);
    width:         calc(100% + 20px);

}

.slides .hoverCardBottom{
   z-index:111;
   
   opacity:.95;
   position: absolute;
   top:100%;
   height:324px;
   min-height: 324px;
   width:100%;
   -webkit-transform: translateY(00%);
	 transform: translateY(00%);
	 transition: transform 200ms linear;
    width: -webkit-calc(100% + 20px);
    width:    -moz-calc(100% + 20px);
    width:         calc(100% + 20px);

}
.hoverCardBottom img,.hoverCardTop img{
  height:324px;
   min-height: 324x;
   width:100%;
}
.slides .hoverCardActiveTop
{

  
  width: -webkit-calc(100% + 20px);
  width:    -moz-calc(100% + 20px);
  width:         calc(100% + 20px);
  height:320px;
  position: absolute;
  
  
  -webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	transition: transform 200ms linear;
  
}
.slides .hoverCardActiveBottom
{

 top:100%;
 margin-top:-50px;
  width: -webkit-calc(00% + 20px);
  width:    -moz-calc(100% + 20px);
  width:         calc(100% + 20px);
  min-height:360px;
  height:360px;
  position: absolute;
  
  
  -webkit-transform: translateY(200%);
	transform: translateY(200%);
	transition: transform 300ms linear;
  
}
.slides .hoverCardCaptionTop{
   z-index:111;
   height:150px;
   position: absolute;
   top:-150px;
   margin:0;
   /* background:#191C2F; */
  color:white;
  opacity:.8;
  
	 transition: transform 400ms linear;
    width: -webkit-calc(100% + 20px);
    width:    -moz-calc(100% + 20px);
    width:         calc(100% + 20px);


}
.slides .hoverCardCaptionTopActive
{
  margin:-10px;
  width: -webkit-calc(100% + 20px);
  width:    -moz-calc(100% + 20px);
  width:         calc(100% + 20px);
  height:150px;
  position: absolute;
  top:-150px;
  left:0;
  padding:5px;
  z-index:11111;
  line-height: 150px;
  
  /* background:#191C2F; */
  color:white;
  opacity:.8;
  -webkit-transform: translateY(150px);
	transform: translateY(150px);
	transition: transform 200ms linear;
  font-family: 'Roboto', sans-serif;
  font-family: 'Rubik Mono One', sans-serif;
  font-size:24px;
}

.slides .hoverCardCaptionBottom{
   z-index:111;
   height:150px;
   position: absolute;
   top:100%;
   bottom:0;
   margin:0;
   /* background:#191C2F; */
  color:white;
  font-size:24px;
  opacity:.8;
	 transition: transform 400ms linear;
    width: -webkit-calc(100% + 20px);
    width:    -moz-calc(100% + 20px);
    width:         calc(100% + 20px);   

}
.slides .hoverCardCaptionBottomActive{
   z-index:111;
   height:150px;
   line-height: 150px;
   position: absolute;
   top:100%;
   margin:0;
   font-size:24px;
   /* background:#191C2F; */
  color:white;
  opacity:.8;
  -webkit-transform: translateY(-150px);
	transform: translateY(-150px);
	transition: transform 200ms linear;
  width: -webkit-calc(100% + 20px);
  width:    -moz-calc(100% + 20px);
  width:         calc(100% + 20px);
  font-family: 'Roboto', sans-serif;
  font-family: 'Rubik Mono One', sans-serif;
  
}

.mainContainer
{
  width:100%;
  min-width:600px;
  margin:0px;
  padding:0px;
  /* max-width:1280px; */
  min-height: 600px;
  
  height:90vh;
  margin-left:auto;
  margin-right:auto;
  position:relative;
  


}

.menu
{
  position:absolute;
  top:0;
  left:25%;
  width:100px;
  min-height: 600px;
  height:90vh;
  z-index:10000;
  -webkit-transform: translateY(110%);
	transform: translateY(110%);
	transition: transform 200ms linear;

}

.navItem
{
  margin-top:20px;
  cursor: pointer;
  font-size:18px;
}
.navItem:hover{
  opacity:.7;
}

@keyframes example {
  0%   {opacity: .25;}
  25%  {opacity: .5;}
  50%  {opacity: .75;}
  100% {opacity: 1;}
}


.fade-in-text
{
  /* animation: fade .5s linear;  */
  animation-name: example;
  animation-duration: 4s; 
  color:white;
  font-family: 'Roboto', sans-serif;
  font-family: 'Rubik Mono One', sans-serif;
  font-family: 'Archivo Black', sans-serif;
  text-align: left;
  padding:10px;
  
  
  /* margin-top:20%; */
}
.fade-in-text h1{
  font-size: 28px;
}
.fade-in-hello
{
  animation: fade .75s ease-in; 
  
  background:linear-gradient(25deg, #191C2F, #107FC9);
  opacity:.97;
  min-height: 600px;
  /* height:90vh; */
  height:640px;
  max-height: 640px;
  width:'25%'; 
  position:'absolute';
  top:120px;
  left:25;
 
  
  z-index:10000;
  /* background:#476388; */
  /* animation-name: example; */
  /* animation-duration: 4s; */
}
.header, .footer
{
  width:100%;
  height:60px;
  background-color: white;
  position:relative;
}
.footer{
  border-top:thin solid lightgray;
  
}
.footerNavWrapper
{
  height:20px;
  vertical-align: 'middle';
  
  
}
.footerNavItem
{
  font-size:16px;
  margin-right:5px;
  margin-left:5px;
  line-height: 14px;
  cursor: pointer;
  font-weight: bold;
  color:grey;
}
.footerNavItem:hover
{
  opacity:.7;
  color:black;
 
}
.divider{
  width:2px;
  height:24px;
  background-color: grey;
  margin:2px;
}

@keyframes fade{
  0% {
    opacity: 0;
    top:-800px;
  }
  100%{
    top:0;
  }
}

.scroll
{
  display: flex;
  justify-content: center;
  align-items: center;
}
.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}
.chevronGreen:before,
.chevronGreen:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background:'green';
}
.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}
.loadingWrapper{
  height:100vh;
  width:100%;
  background-color: white;
  position:fixed;
  top:0;
  left:0;
}
.loadingLogo
{
  
  
  width:400px;
  height:400px;
  position:absolute;
  
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  

}

.loadingLogo img{
  width:100%;
  
}

@keyframes move {
  25% {
    opacity: 1;

  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  display: block;
  margin-top: 75px;
  /* margin-left: -30px; */
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: .25;
  animation: pulse 2s linear alternate infinite;
}
.pageContainer {
  min-height: 800px;
  z-index: 0;
  width: 100%;
  max-width: 1280px;
  /* overflow-y: scroll; */
  position:relative;
}
@media only screen and (min-width: 1280px) {
  
  .pageContainer {
    left: 50%;
    margin-left: -640px;
  }
}
@keyframes pulse {
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 780px) {
.footerNavItem
{
  font-size:10px;
  margin-right:5px;
  margin-left:5px;
  line-height: 10px;
  cursor: pointer;
  font-weight: bold;
  color:grey;
}
/* <div class="d-flex justify-content-center"> */
.footerNavWrapper
{
  height:20px;
  vertical-align: 'middle';
  margin-left:auto;
  margin-right:auto;
}

.loadingContainer
{
  width:100%;
  /* min-width:600px; */
  margin:0px;
  padding:0px;
  /* max-width:1280px; */
  /* min-height: 600px; */
  
  /* height:90vh; */
  margin-left:0;
  margin-right:0; 
  position:relative;
  min-width: 0;
  height:100vh;
  


}
}