p {
  line-height: 1.5;
}
.contact_container {
  display: flex;
  flex-direction: column;
 /* / margin: 0 120px; */
  background-color: white;
}
.contact_header {
  width: 100%;
  height: 150px;
  background-color: #2c4c6c;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 60px;
}
.contact_header-title {
  font-size: 2.5rem;
  line-height: 1.5;
  font-weight: bold;
  color: white;
  font-family: "Roboto", sans-serif;
}
.contact_companies-container {
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 40px;
  padding: 20px 120px;
}
.contact_companies-container h1 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #2c4c6c;
}
.contact_companies-logos {
   display: flex; 
  justify-content: space-evenly;
  align-items: center;
  justify-content: center;
}
.logo_box {
  width: 100px;
  height: 100px;
  margin: 5px 10px;
  cursor: pointer;
  border: 1px solid #2c4c6c;
}
.logo_box.active {
  border: 4px solid #2c4c6c;
  box-shadow: 1px 4px 15px 11px rgba(0, 0, 0, 0.75);
}
.logo_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.selected_company-title {
  width: 640px;
  margin: 20px 0;
   padding-left: 60px; 
  font-size: 2rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #2c4c6c;
}
.company_info-container {
  display: flex;
  margin-bottom: 40px;
}
.company_info {
  width: 40%;
  padding: 0px 60px;
}
.company_info-data {
  font-family: "Roboto", sans-serif;
  margin-bottom: 40px;
}
.company_info-data h1 {
  margin-bottom: 15px;
  color: #2c4c6c;
}
.company_info-data p {
  margin: 2px;
  font-size: 1.5rem;
  font-weight: 300;
  text-indent: 20px;
  color: #3c3c3c;
}

.company_mapBox {
  width: 60%;
  height: 450px;
  background-color: #2c4c6c;
}
