.header
{
  width:100%;
  height:60px;
  border:thin solid white;
  position:relative;
  background:white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  margin: 0 5px;
}