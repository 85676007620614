.about_container {
  display: flex;
  flex-direction: column;
  /* margin: 0 120px; */
  background-color: white;
  position:relative;
}

.whoweare_hero {
  margin-top: 20px;
  z-index: 10;
  width: 100%;
  height: 240px;
  display: flex;
  background-color: transparent;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
}

/* WHO WE ARE PAGE */

.whoweare_hero img {
  width: 100%;
  height: 240px;
  object-fit: contain;
  z-index: 25;
}
.whoweare_hero-box {
  width: 40%;
  height: 120px;
  z-index: 100;
  position: absolute;
  background-color: #2c4c6c;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 20px;
  padding-bottom: 20px;
  font-family: "Roboto", sans-serif;
}
.whoweare_hero-box h1 {
  margin-bottom: 20px;
  font-size: 28px;
}
.whoweare_text-box {
  padding: 40px 120px;
  height: 100%;
  font-family: "Roboto", sans-serif;
}
.whoweare_text-p1 {
  font-size: 24px;
  text-indent: 40px;
}
.whoweare_text-p2 {
  font-size: 20px;
  text-indent: 40px;
  font-weight: 200;
  margin: 20px 20px;
}
.whoweare_text-paragraphs {
  display: flex;
  justify-content: space-between;
}

/* Founder Page*/
.founder_title {
  background-color: #2c4c6c;
  /* width:600px; */
  width:100%;
  height: 120px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}
.founder_content {
  width: 100%;
  height: "auto";
  /* background-color: tomato; */
  margin: 20px 0px;
  display: flex;
}
.founder_img-container {
  width: 38%;
  height: 100%;
  /* background-color:teal; */
  position: relative;
}
.founder_img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.founder_text {
  width: 62%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  line-height: 30px;
  font-size: 18px;
  text-indent: 30px;
}

/*History page */
.history_title {
  height:120px;
  /* width: 600px; */
  width:100%;
  padding: 20px;
  color: white;
  font-family: "Roboto", sans-serif;
  background-color: #2c4c6c;
  margin-top: 20px;
  margin-bottom: 40px;
  /* margin-left:-120px; */
  position: absolute;
  left:0;
}
.history_title h1 {
font-size: 32px;
}
.timeline_box {
  padding: 0px 10px;
 width: 100%;
}
.timeline_year {
  font-size: 32px;
  font-weight: bold;
  color: #2c4c6c;
  text-align: center;
}
.multiple_row{
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #2c4c6c;
}
.timeline_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px; */
  border-bottom: 1px solid #2c4c6c;

}
.timeline_year-img-cont {
  min-width: 100px;
  max-width:100px;
  height: 100px;
  position: relative;
}
.timeline_year-img-cont img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.timeline_year-info {
  color: #2c4c6c;
  font-size: 18px;
  /* width: 50%; */
  /* border:thin solid red;
  min-width: 300px; */
}
.timeline_year-info h3 {
  font-weight: bold;
}

/* Team page */
.team_title {
   /* width: 600px; */
   width:100%;
   height:120px;
   padding-left: 20px;
  background-color: #2c4c6c;
  color: white;
  font-family: "Roboto", sans-serif;
  margin-top: 20px;
  /* padding: 15px 15px 15px 50px; */
}
.team_img-box {
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  padding: 20px;
}
.team_img-card {
  width: 300px;
  height: 300px;
  cursor: pointer;
  position: relative;
  background-color: yellowgreen;
  margin: 10px;
  transition: transform 0.2s;
}
.team_img-card:hover {
  transform: scale(1.1);
}
.team_img-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

/*Team Details Page*/
.member_title {
  width: 100%;
  height: 120px;
  margin-top: 20px;
  background-color: #2c4c6c;
  color: white;
  font-family: "Roboto", sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.member_details {
  display: flex;
  width: 100%;
  /* height: 100%; */
  margin-top: 20px;
  /* background-color: violet; */
  font-family: "Roboto", sans-serif;
}
.member_image {
  text-align: center;
  /* width: 100%; */
  /* height: 400px; */
  position: relative;
  /* background-color: yellow; */
}
.member_image img {
  width: 100%;
  /* height: 100%; */
  /* object-fit: cover; */
}
.member_info {
  /* width: 55%; */
  /* height: 400px; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  font-size: 14px;
}
.member_info h2 {
  color: #2c4c6c;
  font-weight: 800 !important;
  padding-top: 10px;
}
.member_info p {
  margin: 0px;
}
.member_about {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  padding: 10px 30px;
  text-indent: 30px;
  font-weight: 200 !important;
}

/*Veteran Page*/

.veteran_text {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin-top: 20px;
  padding: 0 80px;
  text-indent: 30px;
}
.veteran_card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}
.veteran_card {
  width: 250px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px;
}
.veteran_card-img {
  width: 100%;
  height: 300px;
  position: relative;
}
.veteran_card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.veteran_card-text {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: #4a511e;
  padding: 5px;
  color: white;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

/*Career Page*/
.career_text-p {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  text-indent: 30px;
  margin: 20px 0;
  padding: 0 120px;
}
.mission_statement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}
.career_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 20px 0px;
}
.career_logo-box {
  width: 100px;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.career_logo-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.career_logo-circle_y {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fecf0f;
}
.career_logo-circle_b {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #2c4c6c;
}
.career_wwa {
  height: 100px !important;
  margin-bottom: 30px !important;
  font-size: 56px !important;
}

.logo_container {
  /* text-align:center; */
  display: flex;
  justify-content: space-evenly;
}

.logos {
    height: 100px;
    width: 100px;
    /* padding: 3%; */
    margin: 1% 0; 
}

.timeline_year_end {
  /* padding: 10px; */
  margin-bottom: 2%;
  border-bottom: 1px solid #2c4c6c;
}

.h1Test {
  font-size: 325%;
}
