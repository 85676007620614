
.card {
    width: 350px;
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .2s;
    border: none;
    margin: 0;    
}
.card:hover{
    transform: scale(1.25);
    z-index: 200;
}
.card_image {
    object-fit: cover;
    z-index: 20;
    width: 100%;
    height: 100%;
    
}
.card_text {
    z-index: 100;
    font-weight: 700;
    font-size: 24px;
    color: white;
    position: absolute;
}

