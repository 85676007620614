.about_navbar {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  position: relative;
  background-color: transparent;
  margin-top: 20px;
}
.about_link {
  width: 200px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 40px;
}
.about_link a {
  font-size: 18px;
  line-height: 18px;
  color: #2c4c6c;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
}
.about_link:hover {
  background-color: #2c4c6c;
}
.about_link:hover a {
  color: white;
}