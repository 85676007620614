.mobile-row img{
    display: inline-block;
    width: 50%;
    height: 200px
}

.mobile-link{
    font-size: 18px;
    color: #FFF;
    padding: 5px 20px 8px;
    background-color: black;
    text-align: left;
    cursor:pointer;
}

.mobile-link:hover {
    opacity:.7;
}