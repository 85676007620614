.footer {
  display: flex;
  justify-content: space-between;
}
.footer__logo {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 240px;
}
.footer__logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.footer_nav {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-self: center;
  margin-right: 40px;
  height: 80px !important;
}
@media only screen and (max-width: 760px) {
  .footer {
    flex-direction: column;
    height: 100px;
    justify-content: space-evenly;
  }
  .footer__logo {
    justify-content: center;
    /* margin-left: 5px; */
    margin-left:auto;
    margin-right:auto;
  }
  .footerNavItem {
    font-size: 12px;
    width:auto;
  }
  .footer_nav{
    justify-content: space-evenly;
    margin-right: 0;
    margin-left:0;
  }
}
